<template>
  <div class="content-box-padding">
    <div class="sub-title">固定式大气超级站</div>
    <div class="line-text" style="margin-top: 20px">
      生态系统长期定位研究、生态系统与全球变化科学研究、以及自然资源利用与保护研究提供了综合科技平台，为开展跨区域跨学科的联网观测和联网试验提供了必要的实验设施、仪器设备。
    </div>
    <img
      src="~assets/image/business/生态环境综合监测系统概念图.png"
      alt="生态环境综合监测系统概念图"
      class="img-center"
      style="margin-top: 50px"
    />
    <div class="img-title" style="margin-bottom: 80px">
      生态环境综合监测系统概念图
    </div>

    <div style="display: flex; align-items: flex-start">
      <div class="line-text" style="margin-right: 40px; width: 55s%">
        <div style="margin-bottom: 40px">
          技术能力（立体化监测体系）<br />
          卫星遥感+近地表无人机+生态综合观测站+信息化开发<br />
        </div>
        <div style="margin-bottom: 40px">
          建设内容（要素监测到情景预警）<br />
          人类活动监测+环境在线监测+美丽中国+生态评估+未来预警<br />
        </div>

        监测模式（精准解决问题）<br />
        摸底清+情况明+问题准+措施实+效果好<br />
      </div>
      <img
        src="~assets/image/business/建设内容.png"
        alt="建设内容"
        style="width: 260px"
      />
    </div>
  </div>
</template>
